<template>
  <div class='m-1 mb-5 mt-3'>
    <b-overlay
      :show='loadingInit'
      rounded
      opacity='0.6'
      spinner-small
      spinner-variant='primary'
      class='d-inline-block w-100'
    >
      <b-card
        class='d-flex mb-5'
        border-variant='info'
        header-bg-variant='info'
        header-text-variant='white'
      >
        <template v-slot:header>
          <h1 class='text-white'>
            Selecione as funcionalidades que o usuário terá acesso
          </h1>
        </template>
        <section class='text-center my-2' v-if='userFunctionality && userFunctionality.username'>
          <h5>Adicionar funcionalidades para o usuário: <strong>{{ userFunctionality.username }}</strong></h5>
        </section>
        <section
          style='display: grid;  grid-template-columns: repeat(auto-fill, minmax(25%, auto));'
        >
          <b-form-checkbox-group
            v-model='selectedFunctionalities'
            v-for='(functionality, i) in functionalities'
            :key='i'
            class='product'
            size='lg'
          >
            <b-form-checkbox
              class='pt-2 pb-3'
              :value='functionality._id'
              @change='changeFunctionality(functionality)'
            >
              <section class='d-flex align-items-start mt-1'>
                <span class='word-break product-name'>{{ functionality.name }}</span>
                <b-tooltip
                  :target='functionality._id'
                  custom-class='custom-tooltip'
                  placement='auto'
                  triggers='hover'
                >
                  <p class='text-left'>{{ functionality.description }}</p>
                </b-tooltip>

                <b-icon
                  :id='functionality._id'
                  class='ml-2'
                  font-scale='1'
                  icon='info-circle-fill'
                  variant='info'
                >
                </b-icon>
              </section>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </section>

        <section class='d-flex justify-content-between mt-5'>
          <base-button
            class='w-50 mr-3 btn-cancel'
            @click='goToRouter()'
          >
            <span>Cancelar</span>
          </base-button>

          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='d-inline-block w-50'
          >
            <base-button
              class='w-100 btn-add d-flex align-items-center justify-content-center'
              @click='linkFunctionalities()'
            >
              <span class='mr-2'> Vincular funcionalidades </span>
            </base-button>
          </b-overlay>
        </section>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import api from '@/utils/api'

export default {
  name: 'Functionality',
  data() {
    return {
      functionalities: [],
      selectedFunctionalities: [],
      userFunctionality: null,
      userId: null,
      loading: false,
      loadingInit: false
    }
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Funcionalidades',
      subPage: {
        name: 'Funcionalidades',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  },
  async mounted() {
    this.loadingInit = true
    const { usuario } = this.$route.query
    this.userId = usuario
    await this.getUser()
    await this.getFunctionalities()
    this.loadingInit = false
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    }
  },
  methods: {
    ...mapActions('functionality', {
      handleLinkFunctionalities: 'handleLinkFunctionalities',
      handleGetFunctionalities: 'handleGetFunctionalities'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    ...mapActions('user', {
      handleGetUser: 'handleGetUser'
    }),
    changeFunctionality(functionality) {
      if (functionality.name === 'Gerenciar usuário (somente leitura)') {
        const funcionalidadeGerenciarUsuarioLeitura = !!this.selectedFunctionalities.includes(functionality._id)
        const funcionalidadeGerenciarUsuario = this.functionalities.find((x) => x.name === 'Gerenciar usuário')
        if (funcionalidadeGerenciarUsuarioLeitura && funcionalidadeGerenciarUsuario && funcionalidadeGerenciarUsuario._id) {
          this.selectedFunctionalities = this.selectedFunctionalities.filter((x) => x !== funcionalidadeGerenciarUsuario._id)
        }
      } else {
        if (functionality.name === 'Gerenciar usuário') {
          const funcionalidadeGerenciarUsuarioLeitura  = this.functionalities.find((x) => x.name === 'Gerenciar usuário (somente leitura)')
          const funcionalidadeGerenciarUsuario = !!this.selectedFunctionalities.includes(functionality._id)
          if (funcionalidadeGerenciarUsuario && funcionalidadeGerenciarUsuarioLeitura && funcionalidadeGerenciarUsuarioLeitura._id) {
            this.selectedFunctionalities = this.selectedFunctionalities.filter((x) => x !== funcionalidadeGerenciarUsuarioLeitura._id)
          }
        }
      }
    },
    goToRouter() {
      this.$router.go(-1)
    },
    async getFunctionalities() {
      try {
        const { data } = await this.handleGetFunctionalities()
        const { functionalities } = data
        this.functionalities = functionalities
      } catch (error) {

      }
    },
    async linkFunctionalities() {
      try {
        this.loading = true
        const { data } = await this.handleLinkFunctionalities(
          {
            functionalitiesId: this.selectedFunctionalities,
            userId: this.userId
          }
        )
        this.$bvToast.toast(data.message, {
          title: 'Aviso',
          variant: 'success',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.loading = false
      } catch (error) {

      }
    },
    async getUser() {
      try {
        const { data: response } = await api.get(`admin/users/${this.userId}`)
        const { data } = response
        const { user } = data
        this.userFunctionality = user
        this.selectedFunctionalities = user.functionalities.filter((x) => !x.commonUser).map((y) => y._id)
      } catch (error) {

      }
    }
  }
}
</script>
<style>
.custom-tooltip > .tooltip-inner {
  background: #000000;
  max-width: 800px !important;
  width: 600px !important;
}
</style>
<style lang='scss'>
.product {
  border-radius: 5px;
  border: 1px solid var(--navy-blue);
  padding: 5px;
  margin: 12px 12px 0 0;
}

.product-name {
  color: var(--navy-blue);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.btn-cancel {
  background: transparent !important;
  border-radius: 5px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: var(--navy-blue);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}

.btn-add {
  background: var(--navy-blue) !important;
  border-radius: 5px !important;
  border: 1px solid var(--navy-blue) !important;

  span {
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}
</style>
